/*
    Build a custom select2 theme with our colours.
*/

div .select2-hidden-accessible {
    height: 4.2rem !important;
}

.select2-container {
    width: 100% !important;

    .select2-results__option {
        &:empty {
            &:before {
                content: "No selection";
            }
        }
    }
}

.select2-container--focus {
    .select2-selection--multiple {
        border-color: $primary !important;
        color: $input-focus-color !important;
        background-color: $input-focus-bg !important;
        outline: 0 !important;
        box-shadow: $input-box-shadow, $input-focus-box-shadow !important;
    }
}

.select2-selection--multiple {
    .select2-selection__choice {
        background-color: $secondary !important;
        border-color: darken($secondary, 10%) !important;
    }
}

.select2-container.select2-container--default {
    li.select2-results__
        on--highlighted[aria-selected] {
        background-color: $primary !important;
    }

    .select2-results__option[aria-selected=true] {
        background-color: $secondary !important;
    }
}

.select2-selection--single {
    &, .select2-selection__arrow {
        height: 40px !important;
    }

    .select2-selection__rendered {
        line-height: 40px !important;
    }
}

.select2-append {
    .select2 {
        .select2-selection--single {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.select2-prepend {
    .select2 {
        .select2-selection--single {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}


@import "~select2/src/scss/core";
@import "~select2/src/scss/theme/default/layout";

span.select2-dropdown {
    z-index: 1000000 !important;
}