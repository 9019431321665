@include media-breakpoint-down(md) {
    nav#support-tools {
        padding: 0 0.75rem;

        .navbar-brand {
            .fas {
                margin-left: 0.55rem;
                margin-right: 1rem;
            }
        }
    }
}
