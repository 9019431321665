.h-min-100 {
    min-height: 100%;
}


.card-title.text-black {
    color: $black !important;
}

a.back-link {
    color: #444;
}