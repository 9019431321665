img {
    max-width: 100%;
}

#powered-by-symplicity img {
    width: 12rem;
    opacity: 0.5;
    transition: opacity 0.5s;

    &:hover {
        opacity: 1;
    }
}