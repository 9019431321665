.ui-datepicker-trigger {
    @extend .btn;
    @extend .btn-primary;


    div.ui-widget {
        border: 0;
    }
}

div.ui-widget.ui-widget-content.ui-datepicker {
    padding: 0;
    width: auto;
    left: -1000px;
    top: -1000px;
    position: absolute;
    z-index: 1000000 !important;

    .ui-datepicker-header {
        @extend .p-1;

        .ui-datepicker-prev, .ui-datepicker-next {
            @extend .cursor-pointer;
            width: unset;
            height: unset;
            left: unset;
            right: unset;
            padding: 0.5rem 0.75rem;
            top: 0.3125rem;
            border: none !important;
            position: static;

            .ui-icon.ui-icon-circle-triangle-w, .ui-icon.ui-icon-circle-triangle-e {
                @extend .fas;
                background: none;
                background-repeat: unset;
                display: unset;
                left: unset;
                margin-left: unset;
                margin-top: unset;
                overflow: unset;
                position: unset;
                text-indent: unset;
                top: 0.25rem;
                vertical-align: unset;
                font-size: 1px;
                letter-spacing: -1px;
                color: transparent;
                right: unset;

                &::before {
                    font-size: 1rem;
                    letter-spacing: normal;
                    color: $primary;
                }
            }
        }

        .ui-datepicker-prev {
            @extend .float-left;

            .ui-icon.ui-icon-circle-triangle-w {
                @extend .fa-chevron-left;
            }
        }

        .ui-datepicker-next {
            @extend .float-right;

            .ui-icon.ui-icon-circle-triangle-e {
                @extend .fa-chevron-right;
            }
        }

        .ui-datepicker-title {
            @extend .d-flex;

            select {
                @extend .form-control;
                min-width: 4rem;

                &:not(:last-child) {
                    @extend .mr-2;
                }
            }

            .select2 {
                &:not(:last-child) {
                    @extend .mr-2;
                }

                &, .selection {
                    &, .select2-selection {
                        &, .select2-selection__rendered {
                            outline: none;
                            border: none;
                        }
                    }
                }
            }
        }
    }

    table.ui-datepicker-calendar {
        width: auto;
        @extend .m-1;
        font-size: inherit;
        @extend .table;
        @extend .table-borderless;

        th {
            padding: .7em .3em;
        }

        td {
            padding: 0.0625rem;
        }
    }
}
