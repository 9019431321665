.CodeMirror-hints {
    .CodeMirror-hint {
        @include font-size($font-size-base);

        &.CodeMirror-hint-active {
            background-color: $primary;
        }
    }
}

.CodeMirror {
    @include font-size($font-size-base);
    margin-bottom: 0 !important;

    &.cm-s-singleline, &.cm-s-default {
        font-family: Roboto,-apple-system,BlinkMacSystemFont,'Segoe UI','Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
        @extend .form-control;

        &.cm-s-singleline {
            padding: .28rem 0.75rem;
        }

        &.cm-s-default {
            height: unset;
        }
    }
}
