.validation-alert {
    div.validation-summary-errors {
        ul {
            @extend .pl-3;
            @extend .mb-0;
        }
    }
}

.form-control {
    &.suggest-input-error {
        @extend .is-invalid;
    }
}

.suggest-processing {
    @extend .spinner-border;
    @extend .spinner-border-sm;
    @extend .text-primary
}

.form-horizontal {
    label {
        position: relative;

        .ch-charcount {
            display: block;
            position: absolute;
            right: 0;
        }
    }
}

.ch-charcount {
    float: right;
    color: #999;
    font-weight: normal;

    &.warning {
        @extend .text-warning;
    }

    &.exceeded {
        @extend .text-danger;
    }
}