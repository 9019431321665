html {
    .accordion {
        & > .card {
            overflow: unset;
        }

        .card-title, .card-header {
            .fa-chevron-right {
                transition: transform 0.35s ease-in-out;
            }

            &.open .fa-chevron-right {
                transform: rotate(90deg);
            }
        }
    }
}



.card-header {
    .far.fa-chevron-down {
        transition: transform .5s;
    }

    &.open {
        .fa-chevron-down {
            transform: rotate3d(1,0,0,180deg);
        }
    }
}
