@import "~@careerhub/chubby/scss/_variables";

#notes-container {
    .note {
        background: lighten($warning, 10%);

        &:hover {
            cursor: grab;

            &:active {
                cursor: grabbing;
            }
        }

        &:not(:last-child) {
            @extend .mb-3;
        }

        textarea {
            background: transparent;
            border: none;
            padding: 0;
        }

        .note-text:hover {
            cursor: pointer;
        }
    }

    .note-placeholder {
        height: 6rem;
        width: 100%;
        @extend .mb-3;
        background: rgba(0,0,0,.05);
        @extend .ch-shadow;
    }
}
