@import "~@careerhub/chubby/scss/_variables";
@include media-breakpoint-up(xl) {
    #main-page {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .page.row {
        flex-grow: 1;
    }

    #body {
        display: flex;
        flex-direction: column;
    }
}

.mm-h-100 {
    max-height: 100%;
    min-height: 100%;
}

#nav-primary {
    .primaryNav {
        li div[tabindex]:focus {
            outline: none;
        }
    }
}

a.block-link {
    display: block;
    transition: 0.15s ease-in-out;
    transition-property: background, color;

    &:hover {
        background: rgba($primary, 0.15);
    }
}