.alert {
    &.alert-danger, &.alert-primary, &.alert-info, &.alert-success {
        &, h1, h2, h3, h4, h5, h6, pre, .alert-link {
            color: white;
        }
    }
}

.sidebar-container .navbar-sidebar .navbar-nav .nav-item i.down-icon {
    color: white;
    opacity: 0.25;
    transition: transform 0.5s;
}

ul.nav.nav-secondary .nav-item, .nav.nav-secondary .nav-item a {
    color: #4f5a65;
}