ul.token-input-list {
    overflow: hidden;
    height: auto !important;
    height: 1%;
    border: 1px solid #a7a7a7;
    border-radius: 5px;
    cursor: text;
    min-height: 1px;
    z-index: 999;
    margin: 0;
    padding: 0;
    background-color: white;
    list-style-type: none;
    clear: left;
    li {
        input {
            border: 0;
            width: 100px;
            padding: 0;
            background-color: white;
            margin: 2px 0;
            -webkit-appearance: caret;
        }
    }
}

li.token-input-token {
    overflow: hidden;
    height: auto !important;
    height: 15px;
    margin: 3px;
    padding: 1px 3px;
    background-color: white;
    color: black;
    cursor: default;
    border: 1px solid #ccd5e4;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    float: left;
    white-space: nowrap;

    p {
        display: inline;
        padding: 0;
        margin: 0;
    }

    span {
        color: lignten($primary, 0.5);
        margin-left: 5px;
        font-weight: bold;
        cursor: pointer;
    }
}

li.token-input-selected-token {
    background-color: $primary;
    border: 1px solid #3b5998;
    color: white;
}

li.token-input-input-token {
    float: left;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

div.token-input-dropdown {
    position: absolute;
    width: 400px;
    background-color: white;
    overflow: hidden;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    cursor: default;
    z-index: 1;

    p {
        margin: 0;
        padding: 5px;
        font-weight: bold;
        color: $text-muted;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            background-color: white;
            padding: 3px;
            margin: 0;
            list-style-type: none;
        }

        li.token-input-dropdown-item {
            background-color: white;
        }

        li.token-input-dropdown-item2 {
            background-color: white;
        }

        li.token-input-selected-dropdown-item {
            background-color: $primary;
            color: white;
        }
    }
}

div.token-input-dropdown-facebook {
    ul {
        li {
            em {
                font-weight: bold;
                font-style: normal;
            }
        }
    }
}

ul.token-input-list-invisible {
    overflow: hidden;
    height: auto !important;
    height: calc(1.5em + .75rem + 7px);
    border: .0625rem solid #b4b4b4;
    border-radius: .1875rem;
    cursor: text;
    min-height: 1px;
    z-index: 999;
    margin: 0;
    padding: 0;
    background-color: white;
    list-style-type: none;

    li {
        input {
            border: 0 !important;
            border-radius: 0 !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
            outline: none !important;
            width: 100px;
            padding: 0;
            background-color: white;
            margin: 2px 0;
            -webkit-appearance: caret;
            -moz-appearance: caret;
            appearance: caret;

            &:focus {
                border: 0 !important;
                border-radius: 0 !important;
                -webkit-box-shadow: none !important;
                box-shadow: none !important;
                outline: none !important;
                width: 100px;
                padding: 0;
                background-color: white;
                margin: 2px 0;
                -webkit-appearance: caret;
                -moz-appearance: caret;
                appearance: caret;
            }

            &:invalid {
                border: 0 !important;
                border-radius: 0 !important;
                -webkit-box-shadow: none !important;
                box-shadow: none !important;
                outline: none !important;
                width: 100px;
                padding: 0;
                background-color: white;
                margin: 2px 0;
                -webkit-appearance: caret;
                -moz-appearance: caret;
                appearance: caret;
            }
        }
    }
}

li.token-input-token-invisible {
    overflow: hidden;
    height: auto !important;
    height: 15px;
    margin: .375rem;
    padding: 1px 5px;
    background-color: #eff2f7;
    color: black;
    cursor: default;
    border: 1px solid #ccd5e4;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    float: left;
    white-space: nowrap;

    p {
        display: inline;
        padding: 0;
        margin: 0;
    }

    span {
        color: lignten($primary, 0.5);
        margin-left: 5px;
        font-weight: bold;
        cursor: pointer;
    }
}

li.token-input-selected-token-invisible {
    background-color: $primary;
    border: 1px solid #3b5998;
    color: white;
}

li.token-input-input-token-invisible {
    float: left;
    padding: .375rem .75rem;
    list-style-type: none;
}

div.token-input-dropdown-invisible {
    position: absolute;
    width: 545px;
    background-color: white;
    overflow: hidden;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    *border-right-width: 2px;
    *border-bottom-width: 2px;
    cursor: default;
    z-index: 1;

    p {
        margin: 5px;
        padding: 5px;
        color: $text-muted;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            background-color: white;
            padding: .75rem;
            margin: 0;
            list-style-type: none;

            em {
                font-weight: bold;
                font-style: normal;
            }
        }

        li.token-input-dropdown-item-invisible {
            background-color: white;
        }

        li.token-input-dropdown-item2-invisible {
            background-color: white;
        }

        li.token-input-selected-dropdown-item-invisible {
            background-color: #dbeff8;
        }
    }
}
