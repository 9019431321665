.dialog {
    a {
        @extend .alert-link;
        @extend .d-block;

        &.alert-link {
            @extend .d-inline-block;
        }
    }
}

.message.alert {
    &:not(.alert-info)
    :not(.alert-primary)
    :not(.alert-secondary)
    :not(.alert-warning)
    :not(.alert-danger)
    :not(.alert-success) {
        @extend .d-block;
        padding: 0;
        margin: 0;
        border-radius: 0;
    }
}