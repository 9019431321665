.logo {
    max-width: 5rem;
    position: relative;
    flex-shrink: 1;
    display: flex;
    //fix ie...
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: block;
    }

    img {
        max-width: 100%;
    }

    &.loader {
        &:after {
            //change this variable to scale everything nicely.
            $border: 0.125rem;
            $padding: $border;
            $offset: -($border + $padding);
            content: "";
            padding: $padding;
            position: absolute;
            width: 100%;
            height: 100%;
            border: solid $border white;
            border-top-color: transparent;
            left: $offset;
            top: $offset;
            box-sizing: content-box;
            border-radius: 50%;
            animation: spinner-border .75s linear infinite;
            transition: opacity 0.25s ease-in;
            opacity: 0;
        }

        &.loading:after {
            opacity: 1;
        }
    }
}

.drawer {
    .navbar-sidebar-margin {
        .loader.loading:after {
            border-color: #85c2c2;
            border-top-color: transparent;
        }
    }
}
