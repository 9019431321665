// not perfect but better than the defaults.
.ui-dialog {
    @extend .ch-shadow;

    .ui-dialog-titlebar-close {
        @extend .btn;
        @extend .btn-link;

        &::before {
            content: "\f00d";
            font-family: "Font Awesome 6 Pro";
            font-weight: 900;
        }
    }

    .ui-dialog-buttonset {
        button {
            @extend .btn;
            @extend .btn-secondary;
        }
    }
}

div.ui-widget.ui-widget-content {
    @extend .card;
    font-family: inherit;

    .ui-widget-header {
        @extend .card-header;
        border: 0;
        border-radius: 0;
    }


    a.ui-state-default {
        @extend .btn-secondary;
        @extend .btn;
        @extend .btn-block;
        padding: 0.2rem 0.6rem !important;
        background: transparent;

        &.ui-state-active {
            @extend .btn-primary;
        }

        &.ui-state-highlight:not(.ui-state-active) {
            @extend .btn-outline-info;
        }
    }
}
