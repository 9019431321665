@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $size, $length in $spacers {
            .gap#{$infix}-#{$size} {
                gap: $length !important;
            }
        }
    }
}
