$primary: #0B5D90;
$info: #315790;
$success: #006612;
$warning: #FDC143;
$danger: #AF1A12;
$text-muted: #6f7479;

@import '../common.scss';

@import '../accessible';

.text-warning {
    color: darken($warning, 30%) !important;
}

.text-success {
    color: darken($success, 5%) !important;
}