.input-optional:before {
    color: $text-muted;
    content: "(optional)";
}

label.required:after {
    color: $text-muted;
    content: "(required)";
    padding-left: 0.25rem;
}

// Font awesome regular weight checkboxes
.form-check {
    input.form-check-input[type="checkbox"] {
        ~ label {
            &::after {
                font-weight: 400 !important;
                font-size: 0.8rem !important;
                padding: 0.25rem !important;
                font-family: "Font Awesome 6 Pro","Font Awesome 6 Free" !important;
            }
        }
    }
}


div.form-control {
    &:disabled, &.disabled {
        background-color: $input-disabled-bg;
        height: auto;
    }
}

