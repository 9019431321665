@import "node_modules/shepherd.js/dist/css/shepherd";
#shepherd-tour-container {
    @extend .d-flex;
    position: fixed;
    height: 100%;
    width: 100%;

    padding: 0.5rem;

    @media(min-width: 1024px) {
        padding: 5rem;
    }

    top: 0;
    left: 0;
    overflow-y: auto;
    z-index: 9999;

    .shepherd-element {
        position: relative !important;
        margin: auto !important;
        backface-visibility: hidden;
        transform: none !important;
        left: unset !important;
        top: unset !important;
        @extend .card;
        pointer-events: auto !important;
        @extend .row;
        max-width: unset !important;
        flex-direction: row !important;
        border: 0 !important;

        .tour-splash {
            @extend .d-none;
            @extend .d-lg-flex;
            @extend .col-lg-4;
            @extend .col-xl-5;
            @extend .flex-column;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            padding: 0 !important;
            min-width: 300px;
            width: 50%;
            max-width: 100%;
            background: rgb(210,232,232);
            background: linear-gradient(180deg, rgba(210,232,232,1) 0%, rgba(235,255,255,1) 100%);

            .tour-splash-logo {
                @extend .d-flex;
                @extend .m-5;
                margin-bottom: 0 !important;

                img {
                    @extend .mx-auto;
                    width: 5rem;
                }
            }

            .tour-splash-footer {
                margin-top: auto;

                img {
                    width: 100%;
                }
            }
        }

        &:focus {
            outline: none;
        }

        .shepherd-content {
            @extend .col;
            padding: 0 !important;
            @extend .d-flex;
            @extend .flex-column;

            .shepherd-header {
                color: $primary;
                text-align: left;
                @extend .card-header;
                padding-bottom: 0;

                .shepherd-title {
                    color: $primary;
                    margin: 0;
                    @extend .d-flex;
                    @extend .flex-row;
                    font-size: 1.25rem;
                    font-weight: bold;
                }
            }

            .shepherd-text {
                max-height: unset;
                overflow: auto;
                color: $body-color;
                text-align: left;
                font-size: 1rem;
                @extend .card-body;
            }

            .shepherd-footer {
                @extend .mt-auto;
                @extend .card-footer;
                text-align: right;
                padding: 0.5rem;

                .btn {
                    margin: 0;
                    margin-left: 0.5rem;
                }
            }
        }
    }
}

.shepherd-arrow {
    display: none !important;
}