@media print {
    #support-tools,
    #nav-tools,
    #nav-primary,
    #nav-secondary,
    #nav-tertiary,
    #actions-panel,
    .form-actions,
    .profiler-results {
        display: none !important;
    }

    .bg-main {
        background-color: white !important;
    }

    #body {
        overflow-y: visible !important;
    }

    .sidebar-container{
        overflow-y: visible;
    }
}