table.form-table {
    width: 100%;
    td {
        @extend .form-group;
        padding-bottom: 1rem;
    }
}

.fieldGroup-body {
    @extend .card;
    padding: 1rem;
}

.suggest-list-drop {
    border-bottom: 1px solid rgba(0,0,0,.125);
    overflow: auto;
    max-height: 200px;
    border-bottom-right-radius: .1875rem;
    border-bottom-left-radius: .1875rem;

    ul {
        @extend .list-group;

        li {
            @extend .list-group-item;
            @extend .cursor-pointer;


            &.suggest-list-selected {
                @extend .active;
            }
        }
    }
}

.read-only-field {
    padding: .5rem .75rem;
    min-height: calc(1.5em + .75rem + 7px);
    @extend .form-control, :disabled, .h-auto;
}


label.invalid {
    @extend .text-danger;
    ~.form-control {
        @extend .is-invalid;
    }
}

.section-header {
    &:not(:first-child) {
        margin-top: 2.2rem;
    }
}

.radio-items-container {
    margin: -0.5rem;
    .form-check-inline {
        margin: 0.5rem;
    }
}