.table.vintage-datagrid {
    thead {
        th {
            &.desc, &.asc {
                font-weight: bold;

                &:after {
                    font-family: "Font Awesome 6 Pro", "Font Awesome 6 Free";
                    margin-left: 0.5rem;
                }

                &.desc:after {
                    content: "\f078";
                }

                &.asc:after {
                    content: "\f077";
                }
            }
        }
    }
}
