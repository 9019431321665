.dropdown-submenu {
    position: relative;

    a::after {
        transform: rotate(-90deg);
        position: absolute;
        right: 6px;
        top: .8em;
    }

    .dropdown-menu {
        top: 0;
        left: 100%;
        margin-left: .1rem;
        margin-right: .1rem;
    }
}

#support-tools {
    .dropdown-menu {
        overflow-y: auto;
        max-height: calc(100vh - 80px);
    }
}