div.tokenfield {
    @extend .d-flex;
    @extend .flex-row;
    @extend .align-items-center;
    @extend .flex-wrap;

    div.token {
        margin-top: 0.25rem;
        margin-right: 0.25rem;
        height: 24px;

        .token-label {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .token-input {
        flex-shrink: 0;
        flex-grow: 1;
        min-width: unset !important;
    }

    .twitter-typeahead {
        .tt-menu {
            min-width: 20rem;
            max-width: 1vw;
        }
    }
}

.twitter-typeahead.form-control {
    border: 0;
    input {
        &.tt-input, &.tt-hint {
            height: 100%;
        }
    }
}
